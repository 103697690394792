<template>
    <div :style="mainstyle">
        <h1 style="padding-top:180px;color: white;">电动车验厂认证系统</h1>
        <h2 style="padding-top:20px;color: white;">用户登录</h2>
        <Form ref="adminloginForm" :model="formInline" :disabled="!editable" :rules="ruleInline" style="width:20%;margin:auto;padding-top:40px">
        <FormItem prop="username">
            <i-input type="text" v-model="formInline.username" placeholder="账号" :autofocus="true">
                <Icon type="ios-person-outline" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem prop="password" style="padding-top:30px;">
            <i-input type="password" v-model="formInline.password" placeholder="密码">
                <Icon type="ios-lock-outline" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem prop="ckcompanycode" style="padding-top:30px;">
            <i-input type="text" v-model="formInline.ckcompanycode" placeholder="工厂编码">
                <Icon type="ios-lock-outline" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem style="padding-top:30px;padding-bottom:10px">
            <Button type="primary" @click="handleSubmit('adminloginForm')" style="width:100%">登录</Button>
        </FormItem>
        </Form>
    </div>
  </template>
  <script>
  import {nowdatediff} from '@/zsjs/systemTool.js'
  export default {
    name: 'AdminLogin',
    data () {
      return {
        editable:true,
        formInline: {
          username: '',
          password: '',
          ckcompanycode:null,
        },   
        ruleInline: {
          username: [
            { required: true, message: '请输入账号', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
          ],
          ckcompanycode: [
            { required: true, message: '请输入工厂编码', trigger: 'blur' }
          ],
        },
        mainstyle:{
          backgroundImage:"url("+require("@/assets/ycloginbg.jpg")+")",
          backgroundRepeat: "no-repeat",
          height: "700px"
        },

        
        userdata:null,
        yccompanys:[]
      }
    },
    components: {
    },
    mounted(){
      //this.handleQueryYcCompany()
    },
    methods: {
      handleQueryYcCompany() {
          this.$axios({
              method:'post',
              url:"/api/yccompany/company/query",
              data:null,
              headers:{'Content-Type': 'application/x-www-form-urlencoded'},
              transformRequest: function(obj) {
                  var str = [];
                  for(var p in obj){
                      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                  }
                  return str.join("&");
              }
          }).then(function(res){
              if(res.data.resultCode ==0){
                  var rspdata = res.data.data

                  this.yccompanys =rspdata
                  console.log(this.yccompanys)
              }
              else{
                  this.$Message.error({content:res.data.data.msg, duration:3})
              }
          }.bind(this));   
      },
      handleSubmit (name) {
        console.log(this.yccompanys.length)
        console.log(this.ckcompanyidx)

        if(this.formInline.ckcompanycode==null){

          this.$Message.error({content:'请输入需要验厂的公司编码！',duration:3})
          return
        }
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.editable = false
            this.$axios({
              method:'post',
              url:"/api/ycuser/users/login",
              data:this.formInline,
              headers:{'Content-Type': 'application/x-www-form-urlencoded'},
              transformRequest: function(obj) {
                  var str = [];
                  for(var p in obj){
                      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                  }
                  return str.join("&");
              }
            }).then(function(res){
              if(res.data.resultCode ==0){
                this.$Message.success({content:res.data.msg, duration:3, closable:true})

                let logondata = res.data.data
                this.$store.commit('changeLogin',res.data.data)
                this.userdata = res.data.data
                console.log(this.userdata)
                var newpath = '/business/depart/'+this.formInline.ckcompanycode+'/checkdept'

                this.$router.push({ path: newpath })
              }
              else{
                this.$Message.error({content:res.data.msg, duration:3})
                this.editable = true
              }
            }.bind(this));
            
          } else {
            this.$Message.error('请输入正确的内容')
          }
        })
      },
      onopenchange(action){
          console.log(action)

          if(action){
              if(this.yccompanys.length == 0){
                  this.handleQueryYcCompany()
              }
          }
      }
    }
  }
  </script>
  
  <style>
      .center {
          position: fixed;
          top: 50%;
          left: 50%;
          text-align: center;
          background-color: #ffffcc;
          width:70%;
          height: 70%;
          -webkit-transform: translateX(-50%) translateY(-50%);
      }
  </style>
  